import superUsers from '../../objects/data/superUsers.json'
import apiHelper from '../../objects/apiHelper'
import projectApi from '../../objects/projectApi'
import sourcesApi from '../../objects/sourcesAPI'
import mappingApi from '../../objects/mappingApi'
import stepFunctionsApi from '../../objects/stepFunctionsApi'
import multiPartUploadApi from '../../objects/multiPartUploadApi'
import getProfilingStatus from '../common/getProfilingStatus'
import stepFunctionStatusLoop from '../common/stepFunctionStatusLoop'
import { getMappings } from '../mapping'
import { changeRegion, updatedUploadAction } from '../global'
import { convertData, ENTITLEMENTS } from './internal'
import { redePrecalChecksSkipMap } from '../preCalculation'
import { compareArrays } from '../../../commonFunc'

import {
  setFilesAction,
  initialState as navInitstate
} from '../navigation'

import {
  completedUploadAction,
  createdProjectAction,
  creatingProjectAction,
  deleteSourceAction,
  deletedSourceFabricAction,
  erroredUploadAction,
  loadedProjectDataAction,
  progressedUploadAction,
  saveFinishedAction,
  setProjectFileDirtyFlagAction,
  startedProjectDataLoadAction,
  updatedProfilingAction,
  updatedProjectAction,
  updatedSourceFilesAction,
  updatingProjectAction,
  unauthorizedProjectDataLoadAction,
  setAccessListAction,
  setEdpUploadedStatusAction,
  setEdpFailedStatusAction
} from './actions'

const syncNavigationWithProjectData = (navFile, projectData, fileData, dispatch) => {
  const navFileSameAsInitState = navFile.guid === undefined && navFile.columns.length === 0

  if (projectData.files && projectData.files.length > 0) {
    let selectedFile = projectData.files[0]

    if (!navFileSameAsInitState) {
      const foundfile = projectData.files.find(aFile => aFile.guid === navFile.guid)
      selectedFile = foundfile ?? projectData.files[0]
    }

    const selectedFileColumns = fileData.find(x => x.guid === selectedFile.guid)?.columns || []

    dispatch({
      ...setFilesAction,
      payload: {
        file: { ...selectedFile, columns: selectedFileColumns },
        files: projectData.files
      }
    })
  } else {
    dispatch({
      ...setFilesAction,
      payload: {
        file: navInitstate.file,
        files: []
      }
    })
  }
}

const profileCsvFiles = async (dispatch, getState) => {
  const { project } = getState()

  const profilingFiles = project.files.filter(file =>
    file.profileStatus === 'Profiling'
  )

  const moveOnAfterProfiling = currentFile => async result => {
    const output = result.output ? JSON.parse(result.output) : null
    const { status, error } = getProfilingStatus(result)

    currentFile.isProcessing = false
    currentFile.profileStatus = status
    currentFile.processingFinished = new Date().toISOString()
    currentFile.messageError = output?.errorInfo?.Cause
      ? error
      : null
    const isFileUploadComplete = project.files.every(file =>
      file.src !== 'dataFabric' ||
      file.processingFinished
    )
    dispatch({
      ...updatedUploadAction,
      payload: {
        isFileUploadComplete
      }
    })

    dispatch({
      ...updatedProfilingAction,
      payload: {
        files: project.files
      }
    })
  }

  for (const currentFile of profilingFiles) {
    await stepFunctionStatusLoop(
      currentFile.executionArn,
      ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
      moveOnAfterProfiling(currentFile)
    )
  }
}

const profileFabricFiles = async (dispatch, getState) => {
  const { project } = getState()

  if (project.fabricExecutionArn) {
    const arn = project.fabricExecutionArn

    await stepFunctionsApi
      .waitForCompletion(arn)
      .catch(err => console.error(err))
  }

  await dispatch(loadData(project.id, true))
  return dispatch({
    ...updatedProjectAction,
    payload: true
  })
}

export const loadData = (projectId, refreshForce = false) => async (dispatch, getState) => {
  const {
    global: {
      isReadyToRedirect
    },
    navigation,
    project
  } = getState()

  if (projectId === undefined) {
    return dispatch({
      ...startedProjectDataLoadAction,
      payload: false
    })
  }

  if (project.id !== Number(projectId) || isReadyToRedirect || refreshForce) {
    await dispatch({
      ...startedProjectDataLoadAction,
      payload: true
    })

    try {
      const data = await projectApi.getProject(projectId)
      if (!data) {
        return dispatch(unauthorizedProjectDataLoadAction)
      }

      const { region } = apiHelper.getCountry(data.country)

      sourcesApi.setAPIRegion(region)
      changeRegion(data.country)

      const sourceData = await sourcesApi.getSourceData(projectId)
      const fileData = sourceData[0]
      const profileData = sourceData[1]

      syncNavigationWithProjectData(navigation.file, data, fileData, dispatch)

      return dispatch({
        ...loadedProjectDataAction,
        payload: {
          navigation: data.formatFiles.length > 0
            ? {
                formatFile: data.formatFiles[0],
                formatFiles: data.formatFiles
              }
            : {},
          project: {
            ...data,
            fileData,
            profileData
          }
        }
      })
    } catch (e) {
      console.error('There was an error retrieving the project', e)
      return dispatch(unauthorizedProjectDataLoadAction)
    }
  }
}

export const updateSourceFiles = (projectId, guid, conversionData) => async (dispatch, getState) => {
  const {
    mapping: { mappings },
    project: { files }
  } = getState()
  const didError = conversionData ? await convertData(conversionData, projectId, mappings) : false

  const data = await sourcesApi.getSourceData(projectId)
  const fileData = data[0]
  const profileData = data[1]
  const navFiles = files
    .filter(file => file.profileStatus === 'SUCCEEDED')
    .map(file => {
      const fileTemp = fileData.find(y => y.guid === file.guid)
      if (didError) {
        file.errors = true
      }
      return { ...file, columns: fileTemp.columns }
    })
  const currentNavFile = navFiles.find(x => x.guid === guid) || { columns: [] }
  return dispatch({
    ...updatedSourceFilesAction,
    payload: {
      mapping: {
        mappings: conversionData ? [] : mappings
      },
      project: {
        fileData,
        profileData
      },
      navigation: {
        files: navFiles,
        file: currentNavFile
      }
    }
  })
}

export const setProjectFileDirtyFlag = (guid, isDirty) => async (dispatch, getState) => {
  const {
    project,
    project: {
      files,
      formatFiles
    }
  } = getState()
  const currentFile = files.find(x => x.guid === guid)
  if (formatFiles.length > 0 && !(currentFile.isDirty && isDirty)) {
    if (!isDirty) {
      // eslint-disable-next-line no-return-assign
      files.forEach(x => x.isDirty = isDirty)
    } else {
      currentFile.isDirty = isDirty
    }
    await projectApi.putProject({
      ...project,
      files
    })
    return dispatch({
      ...setProjectFileDirtyFlagAction,
      payload: files
    })
  } else {
    return dispatch({
      ...setProjectFileDirtyFlagAction
    })
  }
}

export const getNamesLdapQuery = text => () => projectApi.getNamesLdap(text)

export const applyAccess = accessList => async (dispatch, getState) => {
  const { project } = getState()
  await projectApi.putProject({ ...project, sharedWithIds: accessList })
  return dispatch({ ...setAccessListAction, payload: accessList })
}

export const getAccess = (sharedWithIds, ownerId, studyType, role, userId, action) => {
  if (ownerId) {
    const shareRole = sharedWithIds.find(share => share.id.toLowerCase() === userId.toLowerCase()) || { role: 'noAccess' }
    const group = (userId.toLowerCase() === ownerId.toLowerCase())
      ? 'Owner'
      : shareRole.role

    const superUserAccess = superUsers.includes(userId.toLowerCase())

    const superUserRedi = studyType === 'redi' && role === 'admin' && group !== 'Peer Reviewer'

    if (action !== 'REVIEW') {
      return ENTITLEMENTS[action].includes(group) || superUserAccess || superUserRedi
    } else {
      return ENTITLEMENTS[action].includes(group)
    }
  }
  return true
}

export const completeUpload = guid => async (dispatch, getState) => {
  const { project: { files } } = getState()
  const currentFile = files.find(x => x.guid === guid)
  currentFile.uploaded = true
  dispatch({ ...completedUploadAction, payload: { files } })
  return dispatch(profileData(guid))
}

export const erroredUpload = guid => async (dispatch, getState) => {
  const { project: { files } } = getState()
  const currentFile = files.find(x => x.guid === guid)
  currentFile.uploaded = false
  currentFile.profileStatus = 'FAILED'
  currentFile.messageError = 'Please check your internet connection'
  currentFile.processingFinished = new Date().toISOString()
  currentFile.isProcessing = false
  const isFileUploadComplete = files.filter(x => x.processingFinished).length > 0
  dispatch({ ...updatedUploadAction, payload: { isFileUploadComplete } })
  return dispatch({ ...erroredUploadAction, payload: { files } })
}

export const profileData = guid => async (dispatch, getState) => {
  const { project: { country, files, id }, project } = getState()
  const currentFile = files.find(x => x.guid === guid)
  const fabricOrEdp = currentFile.src === 'edp'
    ? {
        edp: {
          dataProduct: currentFile.dataProduct,
          table: currentFile.fileName
        }
      }
    : { dataFabric: currentFile.dataFabric }

  const params = !currentFile.src
    ? {
        s3Path: `${apiHelper.getBucketName(country)}/${id}/${guid}/${currentFile.path}`,
        id,
        new: true,
        guid,
        doProfileData: true,
        format: currentFile.format === 'txt' ? 'text' : currentFile.format,
        s3Bucket: `${apiHelper.getBucketName(country)}`,
        reloadFile: false
      }
    : {
        ...currentFile,
        ...fabricOrEdp,
        id,
        new: true,
        columns: currentFile.columns
      }

  const { executionArn } = await stepFunctionsApi.profileData(params)
  currentFile.executionArn = executionArn
  currentFile.profileStatus = 'Profiling'

  dispatch({ ...updatedProfilingAction, payload: { files } })

  project.files.forEach(file => { delete file.s3upload })

  await projectApi.putProject(project)

  const moveOnAfterProfiling = async arn => {
    const output = arn.output ? JSON.parse(arn.output) : null
    const { status, error } = getProfilingStatus(arn)
    currentFile.profileStatus = status
    currentFile.messageError = (output && output.errorInfo && output.errorInfo.Cause) ? error : null
    currentFile.processingFinished = new Date().toISOString()
    currentFile.isProcessing = false
    const isFileUploadComplete = files.every(file => file.processingFinished)
    dispatch({ ...updatedUploadAction, payload: { isFileUploadComplete } })
    return dispatch({ ...updatedProfilingAction, payload: { files } })
  }

  return stepFunctionStatusLoop(
    executionArn,
    ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
    moveOnAfterProfiling
  )
}

export const createProject = () => async (dispatch, getState) => {
  const { user, project } = getState()

  dispatch(creatingProjectAction)

  project.ownerId = user.ownerId
  project.ownerName = user.ownerName

  const createdProjectRes = await projectApi.postProject(project)
  const usingCsvFiles = project.files.some(file => file.src === undefined)
  const { region } = apiHelper.getCountry(project.country)

  dispatch({
    ...createdProjectAction,
    payload: {
      ...project,
      id: createdProjectRes.insertId
    }
  })

  if (usingCsvFiles) {
    await multiPartUploadApi.authorize(user.ownerId, region)

    await Promise.all(project.files.map(file =>
      dispatch(multiPartUploadApi.upload(
        file,
        createdProjectRes.insertId,
        progressedUploadAction,
        completeUpload,
        erroredUpload,
        true,
        project.country
      ))
    ))
  } else if (project.studyType === 'skipMap') {
    dispatch(updateProject(true))
  } else {
    return Promise.all(project.files.map(file => dispatch(completeUpload(file.guid))
    ))
  }
}

export const updateProject = redirectCalculation => async (dispatch, getState) => {
  const { user, project, navigation } = getState()

  const isRedi = project.studyType === 'redi'
  const isSkipMap = project.studyType === 'skipMap'

  const isEDP = project.files.some(file => file.src === 'edp')

  const edpFiles = project.files.filter(file => file.src === 'edp')
  const isEdpFilesUploaded = edpFiles.some(file => file.edpStatus === 'created' || file.edpStatus === 'processing')

  const processingRediCsvFiles = project.files.filter(file =>
    file.processingFinished === undefined &&
    file.src === undefined
  )

  const dataFabricFilesOrEdp = project.files.filter(file =>
    file.processingFinished === undefined &&
   (file.src === 'dataFabric' || file.src === 'edp')
  )

  let canRedirect = false

  // TODO: Larry, 6/27 - set up listener for this event in precalc state and use that flag to combine with state
  try {
    const updatedProject = await projectApi.putProject(project)

    // trigger upload/profiling of files for REDi projects
    if (processingRediCsvFiles.length) {
      const { region } = apiHelper.getCountry(project.country)

      dispatch({
        ...updatedUploadAction,
        payload: {
          isFileUploadComplete: false
        }
      })

      await multiPartUploadApi.authorize(user.ownerId, region)

      return Promise.all(processingRediCsvFiles.map(file =>
        dispatch(multiPartUploadApi.upload(
          file,
          project.id,
          progressedUploadAction,
          completeUpload,
          erroredUpload,
          true,
          project.country
        ))
      ))
    }

    if (isRedi && dataFabricFilesOrEdp.length) {
      await Promise.all(dataFabricFilesOrEdp.map(file =>
        dispatch(completeUpload(file.guid))
      ))
    }
    /*
       1. navigation files are acting as the previous file set after changing uploaded files
       2. still works on initial upload because it's an empty array starting out
    */
    const isFilesSame = compareArrays(navigation.files, project.files)
    if (updatedProject.fabricExecutionArn) {
      try {
        const arn = updatedProject.fabricExecutionArn
        const res = await stepFunctionsApi.waitForCompletion(arn)
        canRedirect = res.status !== 'ABORTED'
      } catch (err) {
        console.error(err)
        canRedirect = false

        if (isEDP && isEdpFilesUploaded) {
          dispatch({
            ...setEdpFailedStatusAction,
            payload: project.files
          })
          throw err
        }
      }
    }
    await dispatch(loadData(project.id, true))

    if (isEDP && isEdpFilesUploaded) {
      await dispatch({
        ...setEdpUploadedStatusAction,
        payload: project.files
      })
    }

    if (isSkipMap && !isFilesSame) {
      dispatch(redePrecalChecksSkipMap)
    }
  } catch (err) {
    console.error(err)
  }

  canRedirect
    ? dispatch(saveFinished)
    : dispatch({
      ...updatedProjectAction,
      payload: redirectCalculation && canRedirect
    })
}

export const saveFinished = async (dispatch, getState) => {
  const {
    project
  } = getState()

  try {
    if (typeof project.id !== 'undefined') {
      await projectApi.putProject(project)

      const isRedirectPayload = project.studyType === 'skipMap'
        ? { redirectCalculation: true }
        : { isReadyToRedirect: true }

      return dispatch({
        ...saveFinishedAction,
        payload: isRedirectPayload
      })
    }
  } catch (e) {
    return dispatch({
      ...saveFinishedAction,
      payload: { isReadyToRedirect: false }
    })
  }
}

export const deleteSourceFabric = fileName => async (dispatch, getState) => {
  // only is used for deleting RH and TY file types
  const {
    project,
    project: {
      files,
      formatFiles,
      id: projectId
    }
  } = getState()
  const filesF = [...files].filter(file => file.fileName !== fileName)
  const formatFilesF = [...formatFiles].filter(file => file.fileName !== fileName)
  const projectPieces = {
    files: filesF,
    formatFiles: formatFilesF
  }
  dispatch({
    ...deletedSourceFabricAction,
    payload: projectPieces
  })
  await projectApi.putProject({
    ...project,
    ...projectPieces
  })
  return dispatch(loadData(projectId, true))
}

export const deleteSource = guid => async (dispatch, getState) => {
  const {
    navigation: { file },
    project: { id: projectId, files }
  } = getState()
  const index = files.findIndex(x => x.guid === guid)
  const updatedFiles = [...files]
  updatedFiles.splice(index, 1)
  dispatch({
    ...deleteSourceAction,
    payload: { files: updatedFiles }
  })

  if (file.guid === guid) {
    const initialFileState = { columns: [] }
    const updatedFile = updatedFiles.length > 0 ? updatedFiles[0] : initialFileState
    dispatch({
      ...setFilesAction,
      payload: {
        file: updatedFile,
        files: updatedFiles
      }
    })
  }

  await mappingApi.removeMappingsByFile(projectId, guid)
  await sourcesApi.deleteSource(projectId, guid, true)
  return dispatch(getMappings(projectId))
}

export const checkForProfilingData = async (dispatch, getState) => {
  const { project } = getState()

  const hasCsvFiles = project.files.some(file =>
    file.profileStatus === 'Profiling'
  )

  const hasFabricFiles = !!project.fabricExecutionArn

  if (hasCsvFiles || hasFabricFiles) {
    dispatch(updatingProjectAction)
  }

  if (hasCsvFiles) {
    await profileCsvFiles(dispatch, getState)
  }

  if (hasFabricFiles) {
    await profileFabricFiles(dispatch, getState)
  }
}
